/* eslint-disable max-lines */
import store from '@/store';
import { OrganisationRole } from '@/store/types';
import { REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY } from '@/services/auth/TokenService';
import { jwtDecode } from 'jwt-decode';
import { UserInfo } from './store/types/auth/index';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/devices'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/page-not-found'
  },
  {
    path: '/page-not-found',
    name: 'error404',
    component: () => import('./views/PageNotFound.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/500',
    name: 'error500',
    component: () => import('./views/Error500.vue'),
    meta: {
      unprotected: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./views/Dashboard.vue')
  },
  {
    path: '/certificate-authorities',
    name: 'certificate-authorities',
    component: () => import('./views/CertificateAuthorities.vue'),
    meta: {
      certificateManagementEnabled: true
    }
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('./views/Certificates.vue'),
    meta: {
      certificateManagementEnabled: true
    }
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('./views/Devices.vue'),
    children: []
  },
  {
    path: '/devices/:uuid',
    name: 'device-details',
    component: () => import('./views/details/DeviceDetails.vue')
  },
  {
    path: '/config-change-templates',
    name: 'ascii-templates',
    component: () => import('./views/AsciiTemplates.vue'),
    meta: {
      cliTemplatesEnabled: true
    }
  },
  {
    path: '/config-change-templates/copy',
    name: 'ascii-templates-copy',
    component: () => import('./views/details/CopyAsciiTemplate.vue'),
    meta: {
      cliTemplatesEnabled: true
    }
  },
  {
    path: '/config-change-templates/:id',
    name: 'config-change-templates-details-by-id',
    component: () => import('./views/details/AsciiTemplateDetails.vue')
  },
  {
    path: '/update-server',
    name: 'update-server',
    component: () => import('./views/AutoUpdateServer.vue'),
    meta: {
      autoUpdateServerEnabled: true
    }
  },
  {
    path: '/smart-update',
    name: 'smart-update',
    component: () => import('./views/SmartUpdate.vue'),
    meta: { onlyIfCloud: true }
  },
  {
    path: '/quickstart-wizard/:step',
    name: 'quickstart',
    component: () => import('./views/QuickstartWizard.vue'),
    meta: {
      onlyIfCloud: true
    }
  },
  {
    path: '/update-server/:uuid',
    name: 'update-server-details',
    component: () => import('./views/details/AutoUpdateServerDetails.vue')
  },
  {
    path: '/update-jobs',
    name: 'update-jobs',
    component: () => import('./views/UpdateJobs.vue'),
    meta: {
      updateJobsEnabled: true
    }
  },
  {
    path: '/update-jobs/:id',
    name: 'update-jobs-details',
    component: () => import('./components/updateJobs/detailsDialog/index.vue')
  },
  {
    path: '/update-wizard',
    name: 'update-wizard',
    component: () => import('./views/UpdateWizard.vue')
  },
  {
    path: '/update-packages',
    name: 'update-packages',
    component: () => import('./views/UpdatePackets.vue'),
    meta: {
      updatePacketManagementEnabled: true
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('./views/Resources.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/LoginView.vue'),
    meta: {
      unprotected: true,
      onlyIfLoggedOut: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('./views/Signup.vue'),
    meta: {
      unprotected: true,
      onlyIfLoggedOut: true,
      onlyIfCloud: true
    }
  },
  {
    path: '/confirm/:confirmationCode',
    name: 'confirmation',
    component: () => import('./views/Confirmation.vue'),
    meta: {
      unprotected: true,
      onlyIfLoggedOut: false
    }
  },
  {
    path: '/reset-password/:resetPasswordCode',
    name: 'resetPassword',
    component: () => import('./views/ResetPassword.vue'),
    meta: {
      unprotected: true,
      onlyIfLoggedOut: true
    }
  },
  {
    path: '/installer',
    name: 'installer',
    component: () => import('./views/Installer.vue'),
    meta: {
      unprotected: true,
      onlyIfLoggedOut: true
    }
  },
  {
    path: '/device-groups',
    name: 'device-groups',
    component: () => import('./views/DeviceGroups.vue')
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('./views/Settings.vue')
  },
  {
    path: '/user-menu',
    name: 'userMenu',
    redirect: '/user-menu/user-profile',
    component: () => import('./views/UserMenu.vue'),
    children: [
      {
        path: 'profile',
        name: 'user-profile',
        component: () => import('./components/settings/UserProfile/index.vue')
      },
      {
        path: 'account-settings',
        name: 'account-settings',
        component: () =>
          import('./components/settings/AccountSettings/index.vue')
      },
      {
        path: 'licenses',
        name: 'licenses',
        component: () => import('./views/LicensesView.vue')
      }
    ]
  },
  {
    path: '/audit-logs',
    name: 'audit-logs',
    component: () => import('./views/AuditLogs.vue'),
    meta: {
      auditLogEnabled: true
    }
  },
  {
    path: '/administration',
    name: 'administration',
    redirect: '/administration/system-information',
    meta: {
      onlyIfNotJustCustomer: true
    },
    children: [
      {
        path: 'system-information',
        meta: {
          onlyIfNotJustCustomer: true
        },
        component: () =>
          import('./components/administration/SystemInformation.vue')
      },
      {
        path: 'user-and-account-management',
        meta: {
          onlyIfNotJustCustomer: true,
          userManagementEnabled: true
        },
        redirect: '/administration/user-and-account-management/accounts',
        children: [
          {
            path: 'accounts',
            meta: {
              onlyIfNotJustCustomer: true
            },
            props: true,
            component: () => import('./views/Accounts.vue')
          },
          {
            path: 'users',
            meta: {
              onlyIfNotJustCustomer: true
            },
            props: true,
            component: () => import('./views/Users.vue')
          },
          {
            path: 'organisations',
            meta: {
              onlyIfNotJustCustomer: true
            },
            props: true,
            component: () => import('./views/Organisations.vue')
          }
        ]
      },
      {
        path: 'system-settings',
        meta: {
          onlyIfNotJustCustomer: true
        },
        component: () =>
          import('./components/administration/SystemSettings/index.vue')
      }
    ]
  }
];

// there is also createWebHashHistory and createMemoryHistory

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

import { useCookies } from 'vue3-cookies';

router.beforeEach((to, from, next) => {
  const install = useCookies().cookies?.get('install-me');
  const isUnprotected = to.matched.some((record) => record.meta.unprotected);

  const onlyIfLoggedOut = to.matched.some(
    (record) => record.meta.onlyIfLoggedOut
  );
  const pathNotForCustomer = to.matched.some(
    (record) => record.meta.onlyIfNotJustCustomer
  );
  const onlyIfCloud = to.matched.some((record) => record.meta.onlyIfCloud);

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

  const parsedToken = accessToken
    ? jwtDecode<UserInfo>(accessToken)
    : ({} as UserInfo);

  const licensedFeatures = parsedToken.licenseExtensions ?? {};
  const loggedIn = !!refreshToken;
  const organisationRoles = parsedToken.organisationRole ?? [];
  const onpremises = store.getters.onpremises;
  const featureFlagMeta = Object.keys(licensedFeatures).reduce(
    (acc, featureFlag) =>
      Object.assign(acc, {
        [featureFlag]: to.matched.some((record) => record.meta[featureFlag])
      }),
    {}
  );

  const featureFlagBlock = Object.entries(featureFlagMeta).find(
    ([featureFlag, meta]) => {
      if (meta && !licensedFeatures[featureFlag]) {
        next('/');
        return;
      }
    }
  );
  if (featureFlagBlock) {
    // next already called.
    return;
  }
  if (to.path === '/installer' && install !== 'true') {
    next('/');
    return;
  }
  if (to.path === '/login' && install === 'true') {
    next({
      path: '/installer'
    });
    return;
  }
  if (!isUnprotected && !loggedIn && from.path !== '/login') {
    if (install === 'true') {
      next({
        path: '/installer'
      });
      return;
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
      return;
    }
  }
  if (loggedIn && onlyIfLoggedOut) {
    next('/');
    return;
  }
  if (onpremises && onlyIfCloud) {
    if (!loggedIn) {
      next('/login');
      return;
    }
    next('/');
    return;
  }
  if (
    pathNotForCustomer &&
    !organisationRoles.find(
      (role: string) =>
        role === OrganisationRole.SUPPLIER ||
        role === OrganisationRole.BROKER ||
        role === OrganisationRole.RESELLER ||
        role === OrganisationRole.PLATFORM_OPERATOR
    )
  ) {
    next('/');
    return;
  }

  next();
});

export default router;
