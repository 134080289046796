<template>
  <div class="container">
    <v-text-field
      v-model="search"
      :label="$t('common.search')"
      hide-details
      prepend-inner-icon="mdi-magnify"
      density="compact"
      variant="solo"
      :bg-color="colors.tableHeader"
      flat
      clearable
      class="searchbar mr-2"
      data-cy="table-searchbar"
      @update:model-value="updateSearch"
    />
    <IconButton
      v-if="!$attrs['no-filter']"
      :config="buttonConfig()"
      :active="filter"
    />
  </div>
</template>

<script lang="ts">
import IconButton from '@/components/common/table/IconButton.vue';
import { ButtonCallbacks } from '@/components/types/DataTable';
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
import { eventBus } from '@/main';

export default defineComponent({
  name: 'DataTableSearchbar',
  components: {
    IconButton
  },
  props: {
    tableId: {
      type: String,
      required: true
    }
  },
  emits: ['update:filter', 'update:search'],
  data() {
    return {
      search: '',
      filter: false,
      colors
    };
  },
  created() {
    // Debounce function
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };
    // Redefine performSearch with a debounced version
    this.performSearch = debounce(this.performSearch.bind(this), 300);
  },
  methods: {
    updateSearch() {
      eventBus.$emit('activateTableLoading', this.tableId);
      this.performSearch();
    },
    performSearch() {
      this.$emit('update:search', this.search);
    },
    updateFilter() {
      this.filter = !this.filter;
      this.$emit('update:filter', this.filter);
    },
    buttonConfig() {
      return {
        id: ButtonCallbacks.FILTER,
        icon: 'mdi-filter',
        activeIcon: 'mdi-filter-remove',
        title: 'common.filter',
        callback: this.updateFilter.bind(this)
      };
    }
  }
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  min-width: 35%;
  justify-content: center !important;
  align-items: center !important;
}
.searchbar {
  min-width: 40px;
  border-radius: 5px;
}
</style>
